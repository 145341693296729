// extracted by mini-css-extract-plugin
export var __wrapper = "panier-module--__wrapper--aRc8a";
export var action = "panier-module--action--jvveh";
export var alternative_price = "panier-module--alternative_price--YDH2y";
export var backButton = "panier-module--backButton--DLZQe";
export var btn = "panier-module--btn--JDvsr";
export var cart = "panier-module--cart--l4Q1A";
export var cartContainer = "panier-module--cartContainer--ATrJb";
export var cartHeader = "panier-module--cartHeader--QJaj8";
export var cart_promo = "panier-module--cart_promo--q++LF";
export var cart_recap = "panier-module--cart_recap--g6DVx";
export var cart_recap_content = "panier-module--cart_recap_content--7SJFX";
export var cart_recap_title = "panier-module--cart_recap_title--QWgZQ";
export var cart_title = "panier-module--cart_title--VhWQp";
export var container1 = "panier-module--container1--KS7g0";
export var contents_icon = "panier-module--contents_icon--MuHeW";
export var contents_items = "panier-module--contents_items--rkuVG";
export var contents_single_item = "panier-module--contents_single_item--4tNWw";
export var ctaButton = "panier-module--ctaButton--ks0V1";
export var ctaInfo = "panier-module--ctaInfo--DSHxy";
export var ctaTitle = "panier-module--ctaTitle--DbF25";
export var ctaWrapper = "panier-module--ctaWrapper--yZK0Y";
export var desktop = "panier-module--desktop--RZ8QX";
export var emptyCartWrapper = "panier-module--emptyCartWrapper--HpjTv";
export var euro = "panier-module--euro--rmgzl";
export var header = "panier-module--header--KRCY-";
export var intro = "panier-module--intro--a3BCK";
export var know_more = "panier-module--know_more--JmLzP";
export var know_more_img = "panier-module--know_more_img--jRfzs";
export var know_more_text = "panier-module--know_more_text--vgux2";
export var logo = "panier-module--logo--Gk2WW";
export var main = "panier-module--main--N5Yig";
export var mobile = "panier-module--mobile--adaUD";
export var productList = "panier-module--productList--tam22";
export var products = "panier-module--products--xGiax";
export var shipping_promo = "panier-module--shipping_promo--obBUg";
export var small = "panier-module--small--yWAqs";
export var spacer = "panier-module--spacer---eiNR";
export var strikethrough = "panier-module--strikethrough--c66zm";
export var title = "panier-module--title--Flfs0";
export var titleImage = "panier-module--titleImage--vCfSB";
export var total = "panier-module--total--Bs02z";
export var totalLine = "panier-module--totalLine--JukPe";
export var wrapper = "panier-module--wrapper--jQG2v";
export var wrapperNav = "panier-module--wrapper-nav--P3X5C";