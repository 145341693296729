import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import ConversionLayout from '../components/conversion-layout';
import CartItem from '../components/panier/cart-item';
import PackCartItem from '../components/panier/cart-item-pack';
import ProductCartItem from '../components/panier/product-cart';
import CartPopupOptions from '../components/panier/cart-popup-options';
import CouponForm from '../components/panier/coupon-form';
import Process, { steps } from '../components/repeatable/process';
import SEO from '../components/seo';
import Reassurance from '../components/static/rea';
import CartContext, { products, programs } from '../context/CartContext';
import useFacebookConversionApi from '../hooks/useFacebookConversionApi';
import useLocalStorage from '../hooks/useLocalStorage';
import * as styles from '../modules/panier.module.scss';

import Layout from '../components/layout';
/**
 * Component to display when the cart is empty
 */
function EmptyCart({ fertilityTestResults }) {
  return (
    <div className={`wrapper ${styles.emptyCartWrapper}`}>
      <div className={styles.ctaWrapper}>
        <h2 className={styles.ctaTitle}>Votre panier est vide !</h2>
        {!fertilityTestResults && (
          <>
            <p className={`${styles.ctaInfo} p0`}>Vous souhaitez savoir si le programme BeMum est fait pour vous ?</p>
            <Link className={`Btn ${styles.ctaButton}`} to={`/test-fertilite/form/`}>
              Je fais le test fertilité
            </Link>
          </>
        )}
        {fertilityTestResults && (
          <>
            <p className="p0">Souhaitez-vous revoir le programme que nous vous avions conseillé ?</p>
            <Link className={`Btn ${styles.ctaButton}`} to={`/resultats/programme-essentiel/`}>
              Voir les résultats de mon test
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

/**
 * Page component
 */
function Cart() {
  const { cart, setCart } = useContext(CartContext);
  const [hasMounted, setHasMounted] = React.useState(false);
  const location = useLocation();
  const [fertilityTestResults] = useLocalStorage('answers', null);
  const [scoreObject] = useLocalStorage('score', null);

  const [subscribedToMarketing, setSubscribedToMarketing] = React.useState(false);

  // const [displaySelectItems, setDisplaySelectItems] = useState(false);

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const productsFromUrl = queryParams.getAll('product');

  //   if (productsFromUrl.length) {
  //     const productKey = productsFromUrl[0];

  //     const productMapping = {
  //       antiox_1mois: { program: 'age', membershipPeriod: 'monthly' },
  //       antiox_3mois: { program: 'age', membershipPeriod: 'three_months' },
  //       antiox_6mois: { program: 'age', membershipPeriod: 'six_months' },
  //       equilibre_1mois: { program: 'sopk', membershipPeriod: 'monthly' },
  //       equilibre_3mois: { program: 'sopk', membershipPeriod: 'three_months' },
  //       equilibre_6mois: { program: 'sopk', membershipPeriod: 'six_months' },
  //     };

  //     const productList = {
  //       essentiel: [],
  //       essentiel_plus: ['coaching'],
  //       antiox: ['antiox'],
  //       antiox_plus: ['antiox', 'coaching'],
  //       equilibre: ['equilibre'],
  //       equilibre_plus: ['equilibre', 'coaching'],
  //       complet: ['equilibre', 'antiox'],
  //       absolu: ['equilibre', 'antiox', 'coaching'],
  //     };

  //     if (productMapping[productKey]) {
  //       const { program, membershipPeriod } = productMapping[productKey];
  //       setCart('add', 'program', program);
  //       setCart('add', 'membershipPeriod', membershipPeriod);
  //       return;
  //     }

  //     setDisplaySelectItems(true);
  //     setCart('add', 'selectItem', productList[productKey] || []);
  //   }
  // }, [location.search]);

  /**
   * Set by google tag manager
   * Used by Facebook Business Manager to deduplicate events
   * @see https://www.facebook.com/business/help/823677331451951?id=1205376682832142
   */
  const [eventId] = useLocalStorage('eventId', null);

  const contentIds = cart?.contents?.items.map((item) => item.id) || [];
  useFacebookConversionApi(
    'PageView',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId
  );
  useFacebookConversionApi(
    'AddToCart',
    location.href,
    fertilityTestResults?.email,
    fertilityTestResults?.firstname,
    fertilityTestResults?.phone,
    eventId,
    cart.total,
    contentIds
  );

  const [modalOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  };

  const [nbOptions, setNbOptions] = useState(0);

  useEffect(() => {
    let newNbOptions = 0;
    const hasCoachingOption = cart.contents.items.find((item) => item.id === products.COACHING);
    const hasAntioxOption = cart.contents.items.find((item) => item.id === products.ANTIOX);
    const hasEquilibreOption = cart.contents.items.find((item) => item.id === products.EQUILIBRE);

    if (hasCoachingOption) newNbOptions += 1;
    if (hasAntioxOption) newNbOptions += 1;
    if (hasEquilibreOption) newNbOptions += 1;

    setNbOptions(newNbOptions);
  }, [cart]);

  useEffect(() => {
    const params = queryString.parse(location.search);

    async function reloadCart() {
      if (params.coaching || params.antiox || params.equilibre) setCart('add', 'program', programs.ESSENTIEL);
      if (params.coaching && params.coaching === 'true') setCart('add', 'product', products.COACHING);
      if (params.antiox && params.antiox === 'true') setCart('add', 'product', products.ANTIOX);
      if (params.equilibre && params.equilibre === 'true') setCart('add', 'product', products.EQUILIBRE);
    }
    reloadCart();
  }, [location, setCart]);

  /**
   * Opt in to Abandoned Cart marketing
   * @see https://modern-javascript.fr/comment-utiliser-une-async-function-dans-un-hook-useeffect-avec-react/
   **/
  useEffect(() => {
    if (subscribedToMarketing) return;
    async function subscribeToAbandonedCartMarketing() {
      if (!fertilityTestResults || !scoreObject) {
        return;
      }

      const tags = [];
      /** cf issue fablife/bemum#1336 audience segmentation */
      if (fertilityTestResults.age >= 35) tags.push('35_ans');
      if (fertilityTestResults.infertility_diagnosis.includes('sopk')) tags.push('sopk');
      if (fertilityTestResults.infertility_diagnosis.includes('endometriose')) tags.push('endo');
      if (fertilityTestResults.baby_journey === 'j-ai-un-diagnostic-d-infertilite') tags.push('suivi_medical');

      const endpoint = `${process.env.GATSBY_API}/hubspot/audience/abandoned-cart/members`;
      setSubscribedToMarketing(true);
      await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          progress: 'page_panier',
          email: fertilityTestResults.email,
          FNAME: fertilityTestResults.firstname,
          BM_PG_LINK: `${location.href}`,
          W_COACHING: scoreObject.length && scoreObject[0].coaching >= 20 ? true : false,
          W_ANTIOX: scoreObject.length && scoreObject[0].antiox >= 20 ? true : false,
          W_EQUILIBR: scoreObject.length && scoreObject[0].poids >= 20 ? true : false,
          tags: tags,
        }),
      });
    }
    // Subscribe only if cart was seen for first time
    const params = queryString.parse(location.search);
    if (!params.coaching && !params.antiox & !params.equilibre) subscribeToAbandonedCartMarketing();
  }, [fertilityTestResults, scoreObject, location]);

  /**
   * This is to avoid rehydration issues
   * Inspired from https://www.joshwcomeau.com/react/the-perils-of-rehydration/#the-solution
   */
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return (
    <Layout banner={!cart.coupon}>
      <div>
        <SEO title="Panier" canonical={`https://www.bemum.co/panier/`} />
        <CartPopupOptions modalOpen={modalOpen} closeModal={closeModal} option={programs.ESSENTIEL} />

        <button className={styles.backButton} onClick={() => navigate(-1)}>
          <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 4L5.5 12.5L15.5 21" stroke="black" />
            <circle cx="12.5" cy="12.5" r="12" stroke="black" />
          </svg>
        </button>

        {cart.contents.items.length === 0 && !cart.contents.program && (
          <EmptyCart fertilityTestResults={fertilityTestResults} />
        )}

        {(cart.contents.items.length || cart.contents.program) && (
          <>
            <div className={styles.intro}>
              <h1 className={styles.title}>Votre panier</h1>
            </div>
            <div className={styles.main}>
              {/** Cart Header */}
              <div className={styles.header}>
                <Process currentStep={steps.CART} />
              </div>

              {/** Cart Content */}
              <div>
                <div className={styles.cart}>
                  <div className={styles.cartContainer}>
                    <div className={styles.cartHeader}>
                      <span></span>
                      <span>Produit</span>
                      <span>Prix</span>
                      <span>Quantité</span>
                      <span>Total</span>
                      <span></span>
                    </div>
                    <div className={styles.products}>
                      {cart?.contents.program === 'shop' ? (
                        <ProductCartItem item={cart.contents.items} />
                      ) : (
                        <PackCartItem item={cart.contents.items} />
                      )}
                    </div>
                  </div>

                  <div>
                    <div className={styles.cart_recap}>
                      <div className={`Center  ${styles.cart_title}`}>Total panier</div>
                      {cart?.membershipPeriod === 'three_months'
                        ? 'Votre programme se renouvelle automatiquement tous les 3 mois'
                        : cart?.membershipPeriod === 'six_months'
                        ? ''
                        : cart?.contents.program !== 'shop'
                        ? 'Votre programme se renouvelle automatiquement tous les 30 jours'
                        : ''}

                      <div className={`Medium ${styles.cart_recap_content}`}>
                        <div className={styles.productList}>
                          <p>Produit</p>
                          <p>
                            {cart?.contents.program === 'sopk' && cart?.membershipPeriod === 'three_months'
                              ? `Program S+ - 3 mois`
                              : cart?.contents.program === 'sopk' && cart?.membershipPeriod === 'six_months'
                              ? `Program S+ - 6 mois`
                              : cart?.contents.program === 'sopk'
                              ? `Program S+ `
                              : cart?.contents.program === 'age' && cart?.membershipPeriod === 'three_months'
                              ? `Program P+ +35ans : 3 mois`
                              : cart?.contents.program === 'age' && cart?.membershipPeriod === 'six_months'
                              ? `Program P+ +35ans : 6 mois `
                              : cart?.contents.program === 'age'
                              ? `Program P+ +35ans`
                              : cart?.contents.program === 'complet' && cart?.membershipPeriod === 'three_months'
                              ? `Program Complet : 3 mois`
                              : cart?.contents.program === 'complet' && cart?.membershipPeriod === 'six_months'
                              ? `Program Complet : 6 mois `
                              : cart?.contents.program === 'complet'
                              ? `Program Complet `
                              : cart?.contents.program === 'essentiel' && cart?.membershipPeriod === 'three_months'
                              ? `Program Essentiel : 3 mois`
                              : cart?.contents.program === 'essentiel' && cart?.membershipPeriod === 'six_months'
                              ? `Program Essentiel : 6 mois `
                              : cart?.contents.program === 'essentiel'
                              ? `Essentiel`
                              : ''}
                            <ul>
                              {cart?.contents.program === 'shop' &&
                                cart?.contents.items.map((product) => <li>{product.label}</li>)}
                            </ul>
                          </p>
                        </div>

                        {/* 
                      {cart?.contents.program !== 'sopk' && cart?.contents.program !== 'age' && (
                        <>
                          {nbOptions === 1 && <span> + {nbOptions} option </span>}
                          {nbOptions > 1 && <span>+ {nbOptions} options </span>}
                        </>
                      )} */}
                      </div>

                      <div className={`mt2 mb3 ${styles.cart_promo}`}>
                        <CouponForm />
                      </div>

                      <div>
                        <div className={styles.total}>
                          <span>Sous-total :</span>
                          <span>{cart.subtotal.toFixed(2)}€</span>
                        </div>

                        <div className={styles.total}>
                          <span>Livraison&nbsp;:</span>
                          <span>{cart.freeShipping ? 'Offert' : '6.5€'}</span>
                        </div>

                        {cart && (cart?.discountAmount || cart?.discountPercent) && (
                          <div className={styles.totalLine}>
                            <span>Code promo&nbsp;:</span>
                            <span>
                              {cart.coupon.coupon.amount_off ? `-${cart.discountAmount}€` : `-${cart.discountPercent}%`}
                            </span>
                          </div>
                        )}
                        <div className={styles.total}>
                          <span>Total :</span>
                          <span>{cart.total.toFixed(2)}€</span>
                        </div>
                      </div>

                      <div className={styles.action}>
                        <Link className={`Btn ${styles.btn}`} to={'/checkout/'}>
                          Continuer
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <Reassurance />
      </div>
    </Layout>
  );
}

export default Cart;
