import React, { useEffect, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './cart-item.module.scss';
import { Trash2 } from 'lucide-react';
import CartContext from '../../context/CartContext';
import { Plus, Minus } from 'lucide-react';

const ProductCartItem = ({ item }) => {
  const { cart, setCart } = useContext(CartContext);

  const data = useStaticQuery(graphql`
    query ProductCartItemQuery {
      pagePanierYaml {
        equilibre {
          publicURL
        }
        antiox {
          publicURL
        }
        omega {
          publicURL
        }
        fertilite {
          publicURL
        }
        coaching {
          publicURL
        }
      }
    }
  `);

  const images = data.pagePanierYaml;

  const productMap = {
    essentielSupplement: { label: 'E+ Fertilité', image: images.fertilite.publicURL },
    equilibre: { label: 'Spécificité poids / SOPK', image: images.equilibre.publicURL },
    antiox: { label: 'Spécificité + 35 ans', image: images.antiox.publicURL },
    omegaSupplement: { label: 'Omega +', image: images.omega.publicURL },
    coaching: { label: 'Coaching Plus', image: images.coaching.publicURL },
  };

  const quantityChange = (product, change) => {
    const new_total = product.quantity + change;

    if (new_total > 0) setCart('add', 'product', product.id, new_total);
  };

  const removeItem = (product) => {
    if (cart.contents.items.length === 1) {
      setCart('reset');
    } else {
      setCart('remove', 'product', product);
    }
  };

  return (
    <>
      {item.map((product) => (
        <div className={styles.wrapper}>
          {/** IMAGE & DESCRIPTION */}
          <div className={styles.productWrapper}>
            <div className={styles.imageWrapper}>
              <img src={productMap[product.id].image} alt={productMap[product.id].label} />
            </div>
          </div>
          <div className={styles.cart_description}>
            <div className={styles.label}>{productMap[product.id].label}</div>
          </div>
          {/** PRIX & QUANTITÉ */}
          <div className={styles.price_wrapper}>
            <div>
              <span className={styles.price}>{product.price.monthly ? product.price.monthly : product.price}€</span>
            </div>
          </div>
          <div className={styles.quantity}>
            <a
              className={styles.quantityButton}
              onClick={() => product.quantity > 1 && quantityChange(product, -1)}
              style={{
                cursor: product.quantity <= 1 ? 'not-allowed' : 'pointer',
                opacity: product.quantity <= 1 ? 0.5 : 1,
              }}
            >
              <Minus />
            </a>
            <span className={styles.quantityInput}>{product.quantity}</span>
            <a className={styles.quantityButton} onClick={() => quantityChange(product, 1)}>
              <Plus />
            </a>
          </div>
          <div className={styles.totalPrice}>
            <span>
              {(product.price.monthly
                ? product.price.monthly * product.quantity
                : product.price * product.quantity
              ).toFixed(2)}
              €
            </span>
          </div>
          {/** ICÔNE DE SUPPRESSION (Trash) */}
          <div className={styles.trash} onClick={() => removeItem(product.id)}>
            <Trash2 size={25} color="#E286B2" />
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductCartItem;
