import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import * as styles from './cart-item.module.scss';
import CartContext from '../../context/CartContext';
import { Trash2 } from 'lucide-react';

function PackCartItem({ item }) {
  const { cart, setCart } = useContext(CartContext);

  const data = useStaticQuery(graphql`
    query PackCartItemQuery {
      pagePanierYaml {
        sopk {
          publicURL
        }
        age {
          publicURL
        }
      }
    }
  `);

  const images = data.pagePanierYaml;

  const programMap = {
    essentiel: { label: 'Programme Essentiel', image: images.age.publicURL },
    sopk: { label: 'Programme S+', image: images.sopk.publicURL },
    age: { label: 'Programme P+ +35 ans', image: images.age.publicURL },
    complet: { label: 'Programme Complet', image: images.sopk.publicURL },
  };

  const program = cart.contents.program;
  const pack = programMap[program] || { label: 'Pack Inconnu', image: images.age.publicURL };

  const membershipLabels = {
    monthly: 'Mensuel',
    three_months: '3 mois',
    six_months: 'Achat unique 6 mois',
  };

  const membershipPeriod = membershipLabels[cart.membershipPeriod];

  const removeItem = () => {
    setCart('reset');
  };

  useEffect(() => {}, [cart]);

  return (
    <div className={styles.wrapper}>
      {/** IMAGE & DESCRIPTION */}
      <div className={styles.productWrapper}>
        <div className={styles.imageWrapper}>
          <img src={pack.image} alt={pack.label} />
        </div>
      </div>
      <div className={styles.cart_description}>
        <div className={styles.label}>
          {pack.label} <span className={styles.period}> {membershipPeriod}</span>
        </div>
      </div>
      {/** PRIX & QUANTITÉ */}
      <div className={styles.price_wrapper}>
        <div>
          <span className={styles.price}>{cart.total}€</span>
        </div>
      </div>

      <div className={styles.quantity}>
        <span className={styles.quantityInput}>1</span>
      </div>

      <div className={styles.totalPrice}>
        <span>{cart.total.toFixed(2)}€</span>
      </div>
      {/** ICÔNE DE SUPPRESSION (Trash) */}
      <div className={styles.trash} onClick={() => removeItem()}>
        <Trash2 size={25} color="#E286B2" />
      </div>
    </div>
  );
}

PackCartItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PackCartItem;
