// extracted by mini-css-extract-plugin
export var cart_description = "cart-item-module--cart_description--fJGm2";
export var imageWrapper = "cart-item-module--imageWrapper--+UQhX";
export var label = "cart-item-module--label--wltj8";
export var period = "cart-item-module--period--6Shue";
export var price_wrapper = "cart-item-module--price_wrapper--sRzJx";
export var productWrapper = "cart-item-module--productWrapper--8H02R";
export var quantity = "cart-item-module--quantity--vi3HV";
export var quantityButton = "cart-item-module--quantityButton--71Ctz";
export var quantityInput = "cart-item-module--quantityInput--19x6D";
export var totalPrice = "cart-item-module--totalPrice--U2Dct";
export var trash = "cart-item-module--trash--rb1Wi";
export var wrapper = "cart-item-module--wrapper--q+vbw";